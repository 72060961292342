import React from 'react';
import { Carousel } from './ui/Carousel';

export const Gear: React.FC = () => {
    return (
        <div style={{ width: "100%"}}>
            {/* En-tête */}
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
                
            }}>
                <div style={{
                    fontWeight: "lighter",
                    fontSize: 'clamp(16px, 1.2vw, 22px)',
                    color: '#979698'
                }}>
                    Capturez le monde sous un nouvel angle
                </div>
                <div style={{
                    fontWeight: 'bolder',
                    fontSize: 'clamp(30px, 3vw, 55px)',
                    margin: "3px 0"
                }}>
                    Libérez votre créativité
                </div>
                <div style={{
                    fontWeight: "lighter",
                    fontSize: 'clamp(16px, 1.2vw, 22px)',
                    color: '#979698'
                }}>
                    Une expertise technique au service de vos images
                </div>
            </div>

            <Carousel />
        </div>
    );
};