import React, { useEffect, useState, ReactNode } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { motion, useAnimation, Variants, AnimationControls } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './App.css';
import MainHeader from './Components/MainHeader';
import { Element } from 'react-scroll';
import useWindowDimensions from './Hooks/useWindoDimensions';
import LandingVideo from './Components/LandingVideo';
import Services from './Components/Services';
import { Projects } from './Components/Projects';
import { About } from './Components/About';
import { Gear } from './Components/Gear';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import { LegalNotice } from './Components/LegalNotice';

interface ScrollAnimationProps {
  children: ReactNode;
  threshold?: number;
  delay?: number;
}

const ScrollAnimation: React.FC<ScrollAnimationProps> = ({ children, threshold = 0.1, delay = 0 }) => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);
  const controls: AnimationControls = useAnimation();
  const [ref, inView] = useInView({ threshold, triggerOnce: false });

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (inView) controls.start('visible');
    else controls.start('hidden');
  }, [controls, inView]);

  if (isMobile) return <>{children}</>;

  const variants: Variants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8, ease: [0.17, 0.67, 0.83, 0.67], delay }
    }
  };

  return (
    <motion.div ref={ref} initial="hidden" animate={controls} variants={variants}>
      {children}
    </motion.div>
  );
};

const App = () => {
  useEffect(() => {
    document.title = "OverDrone";
  }, []);

  return (
    <Router>
      <div style={{ width: "100%", backgroundColor: '#EAEAEA' }}>
        <Element name="top"><MainHeader /></Element>
        <Routes>
          <Route
            path="/"
            element={
              <main className='App-main' style={{ width: "100%" }}>
                <LandingVideo />
                <Element name="Services"><ScrollAnimation><Services /></ScrollAnimation></Element>
                <Element name="Projects"><ScrollAnimation delay={0.1}><Projects /></ScrollAnimation></Element>
                <Element name="About"><ScrollAnimation delay={0.1}><About /></ScrollAnimation></Element>
                <Element name="Gear"><ScrollAnimation threshold={0.2} delay={0.2}><Gear /></ScrollAnimation></Element>
                <Element name="Contact"><Contact /></Element>
              </main>
            }
          />
          <Route path="/mentions-legales" element={<LegalNotice />} />
        </Routes>
        <ScrollAnimation><Footer /></ScrollAnimation>
      </div>
    </Router>
  );
};

export default App;
