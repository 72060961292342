import React from 'react'

interface PicHeaderProps {
    url: string
    title: string
    caption: string
}

const PicHeader: React.FC<PicHeaderProps> = ({ url, title, caption }) => {
    return (
        <div style={{ position: "relative" }}>
            <img
                src={url}
                alt="header"
                style={{
                    width: "90%",
                    marginLeft: "5%",
                    marginTop: "3%",
                    borderRadius: "20px",
                }}
            />
            <div 
                style={{
                    position: "absolute",
                    bottom: "10px", // Adjust for vertical alignment from the bottom
                    left: "7%", // More space from the left
                    right: "7%", // More space from the right
                    display: "flex",
                    justifyContent: "space-between",
                    width: "86%", // Adjust to fit within new left/right margins
                    color: "white",
                    fontFamily: "Arial, sans-serif",
                }}
            >
                <span style={{ fontSize: "clamp(25px, 3vw, 55px)", fontWeight: "bold", alignSelf: "flex-end" }}>{title}</span>
                <span style={{ fontSize: "clamp(12px, 1.5vw, 27.5px)", opacity: 0.7, alignSelf: "flex-end" }}>{caption}</span>
            </div>
        </div>
    )
}

export default PicHeader
