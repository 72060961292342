// types.ts
interface CarouselItem {
    id: number;
    title: string;
    catchphrase: string;
    technical_detail: string;
    url_photo: string;
    more_info: string;
}

export const carouselData: CarouselItem[] = [
    {
        "id": 1,
        "title": "Drone FPV 5'",
        "catchphrase": "Une immersion totale pour des images percutantes",
        "technical_detail": "Drone FPV 5 pouces équipé d'une GoPro Hero 12, conçu pour des prises de vue dynamiques et immersives.",
        "url_photo": "/ressources/fpv.png",
        "more_info": "Avec une vitesse de pointe de 160 km/h et une maniabilité extrême, ce drone FPV 5' est conçu pour des plans rapides et dynamiques. Idéal pour les suivis en mouvement et les prises de vue à haute intensité."
    },    
    {
        "id": 2,
        "title": "Drone stabilisé - DJI Air 3",
        "catchphrase": "Des prises de vue cinématographiques à couper le souffle.",
        "technical_detail": "Un drone stable et précis pour des images fluides et détaillées.",
        "url_photo": "/ressources/air3.png",
        "more_info": "Avec sa double caméra et sa stabilisation avancée, le DJI Air 3 capture des plans lents et majestueux avec une qualité d’image remarquable."
    },
    {
        "id": 3,
        "title": "Cinéwhoop 3.5'",
        "catchphrase": "Une immersion fluide au plus près de l’action.",
        "technical_detail": "Un drone compact et protégé pour des prises de vue précises en environnements restreints.",
        "url_photo": "/ressources/protek.png",
        "more_info": "Avec sa protection d’hélices et son format agile, le Cinéwhoop 3.5' évolue en toute sécurité dans les espaces confinés. Idéal pour des plans immersifs en intérieur ou au plus proche des sujets."
    },
    {
        "id": 4,
        "title": "Caméra Sony A7RV",
        "catchphrase": "Une qualité d’image cinématographique, partout avec vous.",
        "technical_detail": "Un setup complet pour des vidéos fluides et professionnelles.",
        "url_photo": "/ressources/a7rv.png",
        "more_info": "Parfait pour les interviews, les captations en mouvement et les plans cinématographiques, ce boîtier plein format s’adapte à chaque tournage avec souplesse et précision."
    }
    
];
