import React, { useEffect, useState } from 'react';
import useWindowDimensions from '../Hooks/useWindoDimensions';
import emailjs from '@emailjs/browser';

function Contact() {
    const sendEmail = (e: { preventDefault: () => void; target: any; }) => {
        e.preventDefault();
        const form = e.target;

        if (validationData(form)) {
            emailjs.sendForm('service_l85eykh', 'template_6qzb6oq', form, 'AwUdWMe40Q99uiwF3')
            alert("Mail bien envoyé");
            form.reset();
        }
    }

    const [isErrorData, setisErrorData] = useState(false);
    const [errorJSONData, seterrorJSONData] = useState({
        'firstnameError': '',
        'lastnameError': '',
        'emailError': '',
        'messageError': ''
    });

    const validationData = (form: { lastname: { value: string; }; firstname: { value: string; }; email: { value: string; }; message: { value: string; }; }) => {
        let isError = false;
        let errorJSON = {
            'firstnameError': '',
            'lastnameError': '',
            'emailError': '',
            'messageError': ''
        }

        if (form.lastname.value === '') {
            isError = true;
            errorJSON.lastnameError = 'Veuillez saisir votre nom';
        }
        if (form.firstname.value === '') {
            isError = true;
            errorJSON.firstnameError = 'Veuillez saisir votre prénom';
        }
        if (form.email.value === '') {
            isError = true;
            errorJSON.emailError = 'Adresse email incorrecte';
        }
        if (form.message.value === '') {
            isError = true;
            errorJSON.messageError = 'Veuillez saisir un message';
        }

        setisErrorData(isError);
        seterrorJSONData(errorJSON);
        return !isError;
    }

    const inputStyle = {
        backgroundColor: '#EAEAEA',
        padding: '1rem',
        border: '0.5px solid #979698',
        borderRadius: '10px',
        outline: 'none',
        color: '#979698',
        fontSize: '1rem',
        fontWeight: '400'
    };
    
    var width = useWindowDimensions().width;
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        if (width < 768) {
            setIsMobile(true);
        }else{
            setIsMobile(false);
        }
    }, [width]);

    return (
        <div style={{ padding: '2% 8%', maxWidth: '1200px', margin: '0 auto', display: 'flex', flexDirection: isMobile? 'column' : 'row', gap: '1rem'}}>
            <div >
                <div style={{ fontSize: '2.5rem', fontWeight: 'bold', marginBottom: '0.5rem', lineHeight: '1.2' }}>
                    Vous avez un <span style={{ backgroundColor: '#2112B7', color: 'white', padding: '0.2rem 0.5rem' }}>projet?</span>
                </div>
                <div style={{ fontSize: '2.5rem', fontWeight: 'bold', marginBottom: '2rem', lineHeight: '1.2' }}>Contactez-nous</div>
                <p style={{ fontSize: '1rem', marginBottom: '1.5rem', lineHeight: '1.5' }}>
                    Un projet en tête? Discutons-en et créons des images qui marquent les esprits
                </p>
                <a href="mailto:contact@overdrone.fr" style={{ color: '#2112B7', textDecoration: 'none', fontSize: '1rem' }}>
                    contact@overdrone.fr
                </a>
            </div>

            <form onSubmit={sendEmail} style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', width: '100%', maxWidth: '600px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '1rem' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width:'45%' }}>
                        <label style={{ fontSize: '1rem', fontWeight: '500' }}>PRÉNOM<span style={{ color: '#FF0000' }}>*</span></label>
                        <input type="text" name="firstname" style={inputStyle} placeholder="Votre prénom" />
                        {errorJSONData.firstnameError && <p style={{ color: '#FF0000', fontSize: '0.875rem' }}>{errorJSONData.firstnameError}</p>}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width:'45%'}}>
                        <label style={{ fontSize: '1rem', fontWeight: '500' }}>NOM<span style={{ color: '#FF0000' }}>*</span></label>
                        <input type="text" name="lastname" style={inputStyle} placeholder="Votre nom" />
                        {errorJSONData.lastnameError && <p style={{ color: '#FF0000', fontSize: '0.875rem' }}>{errorJSONData.lastnameError}</p>}
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label style={{ fontSize: '1rem', fontWeight: '500' }}>MAIL<span style={{ color: '#FF0000' }}>*</span></label>
                    <input type="email" name="email" style={inputStyle} placeholder="Votre adresse mail" />
                    {errorJSONData.emailError && <p style={{ color: '#FF0000', fontSize: '0.875rem' }}>{errorJSONData.emailError}</p>}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label style={{ fontSize: '1rem', fontWeight: '500' }}>MESSAGE<span style={{ color: '#FF0000' }}>*</span></label>
                    <textarea name="message" style={{ ...inputStyle, minHeight: '150px' }} placeholder="Votre message"></textarea>
                    {errorJSONData.messageError && <p style={{ color: '#FF0000', fontSize: '0.875rem' }}>{errorJSONData.messageError}</p>}
                </div>
                <button type="submit" style={{ backgroundColor: '#2112B7', color: 'white', padding: '1rem 1.5rem', border: 'none', borderRadius: '45px', cursor: 'pointer', fontSize: '1rem', maxWidth: '130px', margin: '0 auto' }}>
                    Envoyer
                </button>
            </form>
        </div>
    );
}

export default Contact;