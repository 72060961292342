import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { CameraAlt, Videocam, Movie } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import { CARDS_DATA } from "../data/serviceCards.data";
import { ServiceCard } from "../Types/servicesCard.types";

export const ServiceCards: React.FC = () => {
  const [selectedService, setSelectedService] = useState<ServiceCard>(CARDS_DATA[0]);
  const [serviceIndex, setServiceIndex] = useState<number>(0);
  const [direction, setDirection] = useState<number>(1); // 1 pour droite, -1 pour gauche
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);
  const [contentHeight, setContentHeight] = useState<number>(0);

  // Amélioré: Détection de redimensionnement plus réactive
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    // Configuration initiale
    handleResize();
    
    // Utilisation d'un écouteur d'événement avec debounce pour plus d'efficacité
    window.addEventListener('resize', handleResize);
    
    // Nettoyage
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Dépendances vides pour n'exécuter qu'au montage

  useEffect(() => {
    const interval = setInterval(() => {
      const nextIndex = (serviceIndex + 1) % CARDS_DATA.length;
      setDirection(1); // Toujours vers la droite pour l'auto-défilement
      setSelectedService(CARDS_DATA[nextIndex]);
      setServiceIndex(nextIndex);
    }, 10000);

    return () => clearInterval(interval);
  }, [serviceIndex]);

  // Calcul de la hauteur maximale des contenus lors du montage et du redimensionnement
  useEffect(() => {
    const calculateMaxHeight = () => {
      setTimeout(() => {
        const contentElements = document.querySelectorAll('.service-content');
        let maxHeight = 0;
    
        contentElements.forEach((element) => {
          const el = element as HTMLElement;
          const rect = el.getBoundingClientRect(); // Plus fiable sur mobile
          const height = rect.height;
    
          if (height > maxHeight) {
            maxHeight = height + 40; // Ajouter un peu de marge
          }
        });
    
        setContentHeight(maxHeight + 20);
      }, 100);
    };
    
    
    calculateMaxHeight();
    window.addEventListener('resize', calculateMaxHeight);
    
    return () => window.removeEventListener('resize', calculateMaxHeight);
  }, [isMobile]);

  // Gestion du clic sur un service
  const handleServiceClick = (service: ServiceCard) => {
    const newIndex = CARDS_DATA.findIndex((s) => s.id === service.id);
    // Définir la direction en fonction de l'index actuel et du nouvel index
    setDirection(newIndex > serviceIndex ? 1 : -1);
    setSelectedService(service);
    setServiceIndex(newIndex);
  };

  // Variantes d'animation pour le slider avec des transitions équilibrées
  const sliderVariants = {
    enter: (direction: number) => ({
      x: direction > 0 ? 250 : -250,
      opacity: 0
    }),
    center: {
      x: 0,
      opacity: 1
    },
    exit: (direction: number) => ({
      x: direction > 0 ? -250 : 250,
      opacity: 0
    })
  };

  // Configuration de transition identique pour les mouvements d'entrée et de sortie
  const transitionConfig = {
    type: "tween", // Utiliser un mouvement linéaire au lieu d'un spring pour plus de cohérence
    duration: 0.4,
    ease: "easeInOut" // Utiliser une courbe d'accélération/décélération symétrique
  };

  return (
    <div style={{ width: isMobile ?'90%':'85%', margin: isMobile ? '1em auto' : '3em auto' }}>
      <div
        style={{
          position: 'relative',
          aspectRatio: '16/9',
          width: isMobile ? '100%' : '60%',
          height: 'auto',
          margin: '0 auto',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: '8px',
          backgroundImage: "url('/ressources/designer.jpg')"
        }}
      >
        {CARDS_DATA.map((service) => (
          <Tooltip key={service.id} title={service.title} arrow>
            <motion.div
              style={{
                position: 'absolute',
                width: '40px',
                height: '40px',
                backgroundColor: selectedService.id === service.id ? '#fff' : 'black',
                borderRadius: '50%',
                cursor: 'pointer',
                left: isMobile ? service.xsmall : service.x,
                top: isMobile ? service.ysmall : service.y,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '2px solid black',
              }}
              whileHover={{
                scale: 1.5,
                backgroundColor: '#ff4500',
              }}
              onClick={() => handleServiceClick(service)}
            >
              {service.id === '1' && (
                <CameraAlt
                  style={{
                    fontSize: selectedService.id === '1' ? 30 : 24,
                    color: selectedService.id === '1' ? '#000' : '#fff',
                  }}
                />
              )}
              {service.id === '2' && (
                <Videocam
                  style={{
                    fontSize: selectedService.id === '2' ? 30 : 24,
                    color: selectedService.id === '2' ? '#000' : '#fff',
                  }}
                />
              )}
              {service.id === '3' && (
                <Movie
                  style={{
                    fontSize: selectedService.id === '3' ? 30 : 24,
                    color: selectedService.id === '3' ? '#000' : '#fff',
                  }}
                />
              )}
            </motion.div>
          </Tooltip>
        ))}
      </div>

      <div 
        style={{ 
          marginTop: '2em', 
          width: '100%', 
          position: 'relative',
          height: contentHeight > 0 ? `${contentHeight}px` : 'auto',
          minHeight: '300px',
          overflow: 'hidden' // Réactivé mais avec une animation plus équilibrée
        }}
      >
        <AnimatePresence initial={false} custom={direction} mode="wait">
          <motion.div
            key={selectedService.id}
            custom={direction}
            variants={sliderVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: transitionConfig,
              opacity: { duration: 0.3 }
            }}
            style={{
              width: '100%',
              position: 'absolute'
            }}
            className="service-content"
          >
            {(() => {
              const ContentComponent = selectedService.content;
              return <ContentComponent />;
            })()}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};