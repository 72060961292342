export const LegalNotice = () => {
    return (
        <div style={{height: 'auto', width:'80%', margin:'2em auto' }}>
            <h1 style={{ marginBottom: '0.5em' }}>Mentions légales</h1>
            <p style={{ marginBottom: '0.5em' }}>Conformément aux dispositions des articles 6-III et 19 de la loi pour la Confiance dans l'Économie Numérique, nous vous informons que :</p>
            
            <h2 style={{ marginBottom: '0.1em' }}>Éditeur du site</h2>
            <p style={{ marginBottom: '0.1em' }}>Le site <strong>OverDrone</strong> est édité par <strong>Nicolas CHALANT</strong>, entrepreneur individuel inscrit sous le numéro SIRET <strong>921 167 458 00014</strong>.</p>
            <p style={{ marginBottom: '0.1em' }}>Adresse : 26 boulevard Victor Schoelcher, 77127 Lieusaint</p>
            <p style={{ marginBottom: '0.1em' }}>Email : <a href="mailto:nicolas.chalant@overdrone.fr">nicolas.chalant@overdrone.fr</a></p>

            <h2 style={{ marginBottom: '0.1em' }}>Activité réglementée</h2>
            <p style={{ marginBottom: '0.1em' }}>Nicolas CHALANT exerce une activité de <strong>télépilote professionnel de drones</strong> conformément à la réglementation en vigueur.</p>
            <p style={{ marginBottom: '0.1em' }}>Numéro d’exploitant UAS : <strong>FRA2d92l96t4ueyb</strong></p>

            <h2 style={{ marginBottom: '0.1em' }}>Hébergement</h2>
            <p style={{ marginBottom: '0.1em' }}>Le site est hébergé par <strong>OVH</strong></p>
            <p style={{ marginBottom: '0.1em' }}>Adresse : 2 rue Kellermann, 59100 Roubaix</p>
            <p style={{ marginBottom: '0.1em' }}>Téléphone : 09 72 10 10 07</p>

            <h2 style={{ marginBottom: '0.1em' }}>Propriété intellectuelle</h2>
            <p style={{ marginBottom: '0.1em' }}>L’ensemble des contenus présents sur ce site (textes, images, vidéos) est la propriété exclusive de OverDrone, sauf mention contraire. Toute reproduction ou utilisation est interdite sans autorisation préalable.</p>

            <h2 style={{ marginBottom: '0.1em' }}>Données personnelles</h2>
            <p style={{ marginBottom: '0.1em' }}>Les informations envoyées via le formulaire de contact sont utilisées uniquement pour répondre aux demandes de devis et ne sont pas stockées. Vous pouvez exercer vos droits d’accès, de modification ou de suppression en envoyant un email à <a href="mailto:nicolas.chalant@overdrone.fr">nicolas.chalant@overdrone.fr</a>.</p>

            <h2 style={{ marginBottom: '0.1em' }}>Responsabilité</h2>
            <p style={{ marginBottom: '0.1em' }}>OverDrone s’efforce de fournir des informations exactes mais ne saurait être tenu responsable des erreurs ou d’une indisponibilité du site.</p>
        </div>
    );
};