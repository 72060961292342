import React from 'react';
import { ImgComparisonSlider } from '@img-comparison-slider/react';
import useWindowDimensions from '../Hooks/useWindoDimensions';

const avant1 = '/ressources/avant1.jpg';
const apres1 = '/ressources/apres1.jpg';


function CompareSlider() {

    return (
        <ImgComparisonSlider style={{ width: '100%', height: '100%', margin: 'auto', padding: '0' }}>
            <img slot="first" src={avant1} style={{ width: '100%' }} />
            <img slot="second" src={apres1} style={{ width: '100%' }} />
        </ImgComparisonSlider>

    );
}

export default CompareSlider;