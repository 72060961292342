import { useEffect, useState } from "react";
import CompareSlider from "../CompareSlider";

export const DetailedEditing = () => {
    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        if (window.innerWidth < 768) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [isMobile]);

    return (
        <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', width: '100%'}}>
            <div style={{ width: isMobile ? '95%' : '45%', padding: '2vw' }}>
                <div style={{ fontSize: "clamp(22px, 1.6vw, 34px)", fontWeight: "bold", textAlign:'center' }}> Montage vidéo </div>
                <div style={{ fontSize: "clamp(18px, 1.4vw, 28px)", fontWeight: "normal", color: '#1B1464', textAlign:'center' }}>Sublimez vos images avec un étalonnage précis. </div>
                <div style={{ fontSize: "clamp(14px, 1vw, 20px)", fontWeight: "normal", marginTop: "1vw", textAlign:'justify' }}>
                Le montage n’est pas qu’une simple étape technique : c’est là que vos images prennent tout leur sens. Grâce à notre expertise, nous sublimerons vos séquences avec une colorimétrie soignée, des transitions fluides et un rythme qui porte l’histoire. Comparez avant/après et voyez par vous-même l’impact d’un montage professionnel sur la narration visuelle. Donnez à vos projets cette touche finale qui fait la différence.
                </div>
            </div>
            <div style={{ width: isMobile ? '100%' : '55%', borderRadius: "15px", overflow: "hidden" }}>
                <CompareSlider />
            </div>
        </div>
    );
};
