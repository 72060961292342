import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link as ScrollLink, scroller } from 'react-scroll';
import '../App.css';
import useWindowDimensions from '../Hooks/useWindoDimensions';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

function MainHeader() {
  const { width } = useWindowDimensions();
  const [smallScreenMode, setSmallScreenMode] = useState(width < 1000);
  const [openBurger, setOpenBurger] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const isLegalPage = location.pathname === '/mentions-legales';

  useEffect(() => {
    setSmallScreenMode(width < 1330);
  }, [width]);

  const sections = [
    { name: 'Services', to: 'Services', offset: 0 },
    { name: 'Réalisations', to: 'Projects', offset: -30 },
    { name: 'Pourquoi Overdrone', to: 'About', offset: 0 },
    { name: 'Équipement', to: 'Gear', offset: -30 },
    { name: 'Contact', to: 'Contact', offset: -30 },
  ];

  const handleNavClick = (to: string, offset: number) => {
    setOpenBurger(false);
    if (isLegalPage) {
      navigate(`/#${to}`);
      setTimeout(() => {
        scroller.scrollTo(to, {
          smooth: true,
          duration: 500,
          offset: offset,
        });
      }, 100);
    }
  };

  const renderLinks = (style: React.CSSProperties | undefined) =>
    sections.map((section) => (
      isLegalPage ? (
        <div
          key={section.to}
          onClick={() => handleNavClick(section.to, section.offset)}
          style={style}
        >
          {section.name}
        </div>
      ) : (
        <ScrollLink
          key={section.to}
          to={section.to}
          spy={true}
          smooth={true}
          duration={500}
          offset={section.offset}
          style={style}
          onClick={() => setOpenBurger(false)}
        >
          {section.name}
        </ScrollLink>
      )
    ));

    const handleDevisClick = () => {
      if (isLegalPage) {
        navigate('/#Contact');
        setTimeout(() => {
          scroller.scrollTo('Contact', {
            smooth: true,
            duration: 500,
            offset: -30,
          });
        }, 100);
      } else {
        scroller.scrollTo('Contact', {
          smooth: true,
          duration: 500,
          offset: -30,
        });
      }
    };
    

  return (
    <div style={{ height: '10vh', maxHeight: '100px', background: 'white', fontFamily: 'Lexend', display: 'flex', alignItems: 'center', justifyContent: smallScreenMode ? 'center' : 'space-between', position: 'relative' }}>
      <img
        src="/ressources/logo.png"
        alt="logo OverDrone"
        style={{ maxWidth: '15vh', maxHeight: '60px', cursor: 'pointer', margin: smallScreenMode ? '0 auto' : '0 3em' }}
        onClick={() => navigate('/')}
      />
      {smallScreenMode ? (
        <div style={{ position: 'absolute', right: '2%', display: 'flex', alignItems: 'center', height: '100%' }}>
          <div
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            onClick={() => setOpenBurger(!openBurger)}
          >
            {!openBurger ? <MenuIcon /> : <CloseIcon />}
          </div>
          {openBurger && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                top: '100%', // Juste en dessous du header
                right: '0', // Coller à droite
                backgroundColor: 'white',
                zIndex: '20',
                width: '220px', // Largeur fixée
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Ombre légère
                marginRight: '-20px'
              }}
            >
              {renderLinks({
                textDecoration: 'none',
                display: 'flex',
                textAlign: 'right',
                padding: '15px 30px', // Assure que les liens sont bien espacés
                fontSize: '1rem',
                cursor: 'pointer',
              })}
            </div>
          )}


        </div>
      ) : (
        <>
          <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '25px' }}>
            {renderLinks({
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
              fontSize: '1.2rem',
              cursor: 'pointer',
              color: '#000',
              fontWeight: '300',
            })}
          </div>

          <button
            style={{
              position: 'absolute',
              right: '15%',
              padding: '7px 25px',
              backgroundColor: 'white',
              border: '2px solid #2112B7',
              borderRadius: '20px',
              fontSize: '1rem',
              fontWeight: 'bold',
              color: '#2112B7',
              cursor: 'pointer',
              transition: 'all 0.3s ease',  // Ajout d'une transition douce
            }}
            onClick={() =>handleDevisClick()}
            onMouseEnter={(e) => {
              (e.target as HTMLButtonElement).style.backgroundColor = '#2112B7'; // Changer la couleur de fond au survol
              (e.target as HTMLButtonElement).style.color = 'white'; // Changer la couleur du texte au survol
            }}
            onMouseLeave={(e) => {
              (e.target as HTMLButtonElement).style.backgroundColor = 'white'; // Rétablir la couleur de fond initiale
              (e.target as HTMLButtonElement).style.color = '#2112B7'; // Rétablir la couleur du texte initiale
            }}
          >
            Devis
          </button>

        </>
      )}
    </div>
  );
}

export default MainHeader;
