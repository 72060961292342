import { useEffect, useState } from "react";

export const DetailedPhoto = () => {
    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        if (window.innerWidth < 768) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [isMobile]);

    return (
        <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', width: '90%', marginLeft: "5%" }}>
            {/* Section Texte */}
            <div style={{ width: isMobile ? '95%' : '45%', padding: '2vw' }}>
                <div style={{ fontSize: "clamp(22px, 1.6vw, 34px)", fontWeight: "bold", textAlign:'center' }}> Photographie aérienne </div>
                <div style={{ fontSize: "clamp(18px, 1.4vw, 28px)", fontWeight: "normal", color: '#1B1464', textAlign:'center' }}> Explorez des perspectives inédites. </div>
                <div style={{ fontSize: "clamp(14px, 1vw, 20px)", fontWeight: "normal", marginTop: "1vw", textAlign:'justify' }}>
                Capturez des paysages à couper le souffle avec des photos aériennes qui révèlent la beauté cachée du monde. Nos drones de haute précision offrent une stabilité parfaite et une résolution exceptionnelle pour saisir chaque détail, même à grande hauteur. Offrez à vos projets visuels un point de vue unique et impressionnant.
                </div>
            </div>
            
            {/* Section Image */}
            <div style={{ width: isMobile ? '100%' : '55%', padding: isMobile ? '2vw' : '0' }}>
                <img src="/ressources/church.jpg" alt="Fantoft church" style={{ width: '100%', borderRadius: "15px" }} />
            </div>
        </div>
    );
};
