import React from 'react';
import { projects, bonusProjects } from '../data/projects.data';
import { ProjectCard } from './ProjectCard';

export const Projects: React.FC = () => {
    return (
        <div style={{ width: "90%", maxWidth: "1200px", margin: "0 auto" }}>
        
            {/* En-tête */}
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "8%", marginBottom: "5%" }}>
                <div style={{ fontWeight: "lighter", fontSize: 'clamp(16px, 1.2vw, 22px)', color: '#979698' }}>
                    Notre savoir-faire en images
                </div>
                <div style={{ fontWeight: 'bolder', fontSize: 'clamp(30px, 3vw, 55px)', margin: "3px 0" }}>
                    Nos réalisations
                </div>
                <div style={{ fontWeight: "lighter", fontSize: 'clamp(16px, 1.2vw, 22px)', color: '#979698' }}>
                    Découvrez nos projets les plus marquants
                </div>
            </div>

            {/* Liste des projets */}
            <div style={{ display: "flex", flexDirection: 'column', width: "90%", margin: "3% auto 0" }}>
                {projects.map((project) => (
                    <ProjectCard key={project.id} project={project} />
                ))}
            </div>

            {/* Autres réalisations */}
            <div style={{ width: "90%", marginLeft: "5%" }}>
                <div style={{ fontWeight: "bold", fontSize: "clamp(18px, 2vw, 24px)", marginBottom: "16px" }}>
                    Autres réalisations :
                </div>
                <div style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    gap: "20px"
                }}>
                    {bonusProjects.map((project) => (
                        <div key={project.id} style={{
                            flex: "1 1 calc(25% - 20px)", 
                            minWidth: "150px",
                            textAlign: "center"
                        }}>
                            <div style={{ fontWeight: "bold", fontSize: "clamp(14px, 1.5vw, 18px)" }}>
                                {project.title}
                            </div>
                            <div style={{ fontSize: "clamp(12px, 1vw, 16px)", color: "#555" }}>
                                {project.client} {project.date}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    );
};
