import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link as ScrollLink, scroller } from 'react-scroll';
import { NavLink } from 'react-router-dom';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import useWindowDimensions from '../Hooks/useWindoDimensions';

const Footer: React.FC = () => {
  const logo = '/ressources/logo.png';
  const { width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(width < 768);
  const location = useLocation();
  const navigate = useNavigate();
  const isLegalPage = location.pathname === '/mentions-legales';

  useEffect(() => {
    setIsMobile(width < 768);
  }, [width]);

  const sections = [
    { name: 'Services', to: 'Services', offset: 20 },
    { name: 'Réalisations', to: 'Projects', offset: -30 },
    { name: 'Pourquoi Overdrone?', to: 'About', offset: 20 },
    { name: 'Équipement', to: 'Gear', offset: -30 },
    { name: 'Contact', to: 'Contact', offset: -30 },
  ];

  const handleNavClick = (to: string, offset: number) => {
    if (isLegalPage) {
      navigate(`/#${to}`);
      setTimeout(() => {
        scroller.scrollTo(to, {
          smooth: true,
          duration: 500,
          offset: offset,
        });
      }, 100);
    }
  };

  const linkStyle: React.CSSProperties = {
    color: '#979698',
    fontSize: 'clamp(18px, 1.2vw, 30px)',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    transition: 'color 0.3s ease',
    margin: isMobile ? '0 auto' : '0'
  };

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ width: isMobile ? '90%' : '50%', margin: '2rem auto', backgroundColor: 'black', height: '1px' }} />
      <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: '1rem', justifyContent: isMobile ? 'center' : 'space-between', width: isMobile ? '90%' : '50%', margin: '2em auto' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {sections.map((section, index) => (
            isLegalPage ? (
              <div key={index} onClick={() => handleNavClick(section.to, section.offset)} style={linkStyle} className="footer-link">
                {section.name}
                <span className="hover-icon">
                  <NorthEastIcon style={{ width: 16, height: 16, color: 'white', backgroundColor: 'black', borderRadius: '50%', padding: '2px' }} />
                </span>
              </div>
            ) : (
              <ScrollLink
                key={index}
                to={section.to}
                spy={true}
                smooth={true}
                duration={500}
                offset={section.offset}
                style={linkStyle}
                className="footer-link"
              >
                {section.name}
                <span className="hover-icon">
                  <NorthEastIcon style={{ width: 16, height: 16, color: 'white', backgroundColor: 'black', borderRadius: '50%', padding: '2px' }} />
                </span>
              </ScrollLink>
            )
          ))}
        </div>
        <img src={logo} alt='logo' style={{ width: isMobile ? '50vw' : 'auto', height: isMobile ? 'auto' : '15vh', margin: isMobile ? '1em auto' : '1em 0' }} />

        <ScrollLink to="top" spy={true} smooth={true} duration={500}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <div style={{ fontSize: 'clamp(15px, 1vw, 30px)', cursor: 'pointer' }}>Haut de la page</div>
            <ArrowUpwardIcon style={{ width: 25, height: 25, color: 'black', cursor: 'pointer' }} />
          </div>
        </ScrollLink>
      </div>
      <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: '1rem', justifyContent: 'space-between', width: isMobile ? '90%' : '50%', margin: '0 auto', paddingBottom: '2rem', alignItems: isMobile ? 'center' : 'flex-start' }}>
        <div>©2025 Overdrone, Tous droits réservés</div>
        <NavLink to="/mentions-legales" style={{ cursor: 'pointer', textDecoration: 'underline', color: 'black' }}>Mentions légales</NavLink>
      </div>
      <style>
        {`
          .footer-link {
            position: relative;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            text-decoration: none;
          }
          .hover-icon {
            opacity: 0;
            transition: opacity 0.3s ease;
          }
          .footer-link:hover {
            color: black!important;
          }
          .footer-link:hover .hover-icon {
            opacity: 1;
          }
        `}
      </style>
    </div>
  );
};

export default Footer;
