import React, { useEffect, useState } from 'react';
import PicHeader from './ui/PicHeader';

export const About = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <PicHeader url="/ressources/bergen.jpg" title="Pourquoi Overdrone?" caption="Bergen, Norway" />

            <div style={{
                fontFamily: 'Arial, sans-serif',
                color: '#333',
                maxWidth: '1200px',
                margin: '0 auto',
                padding: '40px 2em',
                textAlign: 'left'
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 'clamp(10px, 2vw, 30px)',
                    fontSize: 'clamp(10px, 0.9vw, 14px)',
                    marginBottom: '40px'
                }}>
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ fontWeight: 'bold', fontSize: 'clamp(20px, 2.5vw, 30px)', marginBottom: '2px' }}>+7ans</div>
                        <div style={{ color: '#666', fontSize: 'clamp(10px, 1vw, 14px)' }}>d'expérience en vol de drone</div>
                    </div>
                    <div style={{ fontSize: 'clamp(20px, 2.5vw, 30px)', fontWeight: 'bold', textAlign: 'center' }}>/</div>
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ fontWeight: 'bold', fontSize: 'clamp(20px, 2.5vw, 30px)', marginBottom: '2px' }}>CATT</div>
                        <div style={{ color: '#666', fontSize: 'clamp(10px, 1vw, 14px)' }}>vols conformes à la réglementation</div>
                    </div>
                    <div style={{ fontSize: 'clamp(20px, 2.5vw, 30px)', fontWeight: 'bold', textAlign: 'center' }}>/</div>
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ fontWeight: 'bold', fontSize: 'clamp(20px, 2.5vw, 30px)', marginBottom: '2px' }}>5.3K</div>
                        <div style={{ color: '#666', fontSize: 'clamp(10px, 1vw, 14px)' }}>haute qualité professionnelle</div>
                    </div>
                </div>

                <h2 style={{
                    fontSize: 'clamp(18px, 1.4vw, 28px)',
                    fontWeight: 'bold',
                    marginBottom: '30px',
                    textAlign: 'center'
                }}>
                    Votre partenaire pour capturer l'extraordinaire depuis les airs
                </h2>

                <p style={{
                    fontSize: 'clamp(12px, 1vw, 16px)',
                    lineHeight: '1.6',
                    marginBottom: '20px',
                    textAlign: 'justify'
                }}>
                    <strong>Overdrone</strong> combine plus de 7 années d'expertise dans la réalisation audiovisuelle et le pilotage de drones. Que ce soit pour des productions cinématographiques, des vidéos institutionnelles ou des captations d'événements, l'entreprise met à votre disposition un savoir-faire technique et artistique pour des images percutantes, à la fois aériennes et terrestres.
                </p>

                <p style={{
                    fontSize: 'clamp(12px, 1vw, 16px)',
                    lineHeight: '1.6',
                    marginBottom: '20px',
                    textAlign: 'justify'
                }}>
                    Certifié par la <strong>DGAC</strong> (Direction Générale de l'Aviation Civile), Overdrone réalise des vols conformes à la réglementation avec des drones <strong>homologués et assurés</strong>. En complément des prises de vue aériennes, l'équipe utilise également des caméras stabilisées au sol pour garantir une couverture complète et un rendu professionnel adapté aux exigences de chaque projet.
                </p>

                <p style={{
                    fontSize: 'clamp(12px, 1vw, 16px)',
                    lineHeight: '1.6',
                    textAlign: 'justify'
                }}>
                    Grâce à une approche mêlant créativité et expertise technique, Overdrone cherche à apporter une véritable valeur ajoutée à chaque réalisation. De la captation d'images spectaculaires à la création de récits visuels impactants, l'entreprise s'engage à transformer vos idées en productions <strong>uniques, pensées pour captiver et inspirer</strong>.
                </p>
            </div>
        </>
    );
};
