import React from 'react';
import { ProjectType } from '../data/projects.data';

interface ProjectCardProps {
    project: ProjectType;
}

export const ProjectCard: React.FC<ProjectCardProps> = ({ project }) => {

    const openLink = (project: string | URL | undefined) => {
        window.open(project, '_blank');
    }

    return (
        <div style={{
            width: '100%',
            marginBottom: '24px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
            <div style={{
                width: '100%',
                backgroundColor: '#d1d5db',
                borderRadius: '12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '18px',
                fontWeight: '600',
                overflow: 'hidden'
            }}>
                <img src={project.imageUrl} alt={project.title} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </div>
            <div style={{ marginTop: '16px', width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '8px', alignItems: 'center', marginBottom: '8px' }}>
                    <div style={{ display: 'flex', gap: '8px' }}>
                        {project.tags.map((tag, index) => (
                            <span key={index} style={{
                                padding: '6px 14px',
                                fontSize: 'clamp(10px, 1vw, 14px)',
                                fontWeight: '600',
                                borderRadius: '16px',
                                backgroundColor: tag === 'Cinéma' ? '#F3E8FF' : tag === 'Événementiel' ? '#DBEAFE' : tag === 'Culture' ? '#FFEDD5' : '#D1FAE5',
                                color: tag === 'Cinéma' ? '#7C3AED' : tag === 'Événementiel' ? '#1E40AF' : tag === 'Culture' ? '#9A3412' : '#065F46'
                            }}>
                                {tag}
                            </span>
                        ))}
                    </div>
                    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                        <span style={{ fontSize: 'clamp(10px, 1vw, 14px)', color: '#4b5563' }}>{project.date}</span>
                        {project.link && 
                            <button style={{
                                padding: '6px 14px',
                                fontSize: "clamp(10px, 1vw, 14px)",
                                borderRadius: '16px',
                                cursor: 'pointer',
                                border: '0.5px solid rgb(93, 93, 93)',
                                backgroundColor: '#EAEAEA', // couleur de fond initiale
                                color: 'rgb(93, 93, 93)', // couleur du texte initiale
                                transition: 'all 0.3s ease', // transition douce
                            }} 
                            onClick={() => openLink(project.link)}
                            onMouseOver={(e) => {
                                e.currentTarget.style.backgroundColor = 'rgb(93, 93, 93)'; // couleur au survol
                                e.currentTarget.style.color = 'white'; // couleur du texte au survol
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.backgroundColor = '#EAEAEA'; // couleur initiale après survol
                                e.currentTarget.style.color = 'rgb(93, 93, 93)'; // couleur du texte initiale après survol
                            }}>
                                Lien du projet
                            </button>
                        }
                    </div>
                </div>
                <h2 style={{ fontSize: 'clamp(16px, 1.5vw, 22px)', fontWeight: 'bold' }}>{project.title}</h2>
                <p style={{ color: '#4b5563', fontSize: 'clamp(12px, 1vw, 16px)', marginTop: '4px' }}>{project.description}</p>
                <p style={{ fontSize: 'clamp(12px, 1vw, 16px)', marginTop: '8px' }}>
                    <span style={{ fontWeight: '600' }}>Client:</span> {project.client}
                </p>
            </div>
        </div>
    );
};
