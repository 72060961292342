import React from "react";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
}

const Button: React.FC<ButtonProps> = ({ text, className, ...props }) => {
  return (
    <button
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        backgroundColor: "white",
        color: "black",
        padding: "clamp(8px, 2vw, 12px) clamp(16px, 3vw, 20px)",
        fontSize: "clamp(1rem, 2.5vw, 1.2rem)",
        fontWeight: "bold",
        border: "none",
        borderRadius: "50px",
        cursor: "pointer",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        transition: "background 0.3s ease",
      }}
      onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
      onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "white")}
      {...props}
    >
      <div
        style={{
          width: "clamp(20px, 4vw, 30px)",
          height: "clamp(20px, 4vw, 30px)",
          backgroundColor: "black",
          color: "white",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PlayArrowIcon style={{ fontSize: "clamp(1rem, 2.5vw, 1.5rem)" }} />
      </div>
      {text}
    </button>
  );
};

export default Button;