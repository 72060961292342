import React, { useState, useEffect } from 'react';
import { carouselData } from '../../data/gear.data';

export const Carousel: React.FC = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [transitioning, setTransitioning] = useState(false);
    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);

    const handlePrevious = () => {
        if (!transitioning) {
            setTransitioning(true);
            setShowMoreInfo(false);
            setCurrentIndex((prev) =>
                prev === 0 ? carouselData.length - 1 : prev - 1
            );
        }
    };

    const handleNext = () => {
        if (!transitioning) {
            setTransitioning(true);
            setShowMoreInfo(false);
            setCurrentIndex((prev) =>
                prev === carouselData.length - 1 ? 0 : prev + 1
            );
        }
    };

    const handleTouchStart = (e: React.TouchEvent) => {
        setTouchStart(e.touches[0].clientX);
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        setTouchEnd(e.touches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (!touchStart || !touchEnd) return;

        const distance = touchStart - touchEnd;
        const minSwipeDistance = 50;

        if (Math.abs(distance) < minSwipeDistance) return;

        if (distance > 0) {
            handleNext();
        } else {
            handlePrevious();
        }

        setTouchStart(0);
        setTouchEnd(0);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setTransitioning(false);
        }, 300);
        return () => clearTimeout(timer);
    }, [currentIndex]);

    // Ajout du passage automatique après un certain délai (5 secondes) si la carte n'est pas ouverte
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!showMoreInfo) {  // Si "voir plus" n'est pas activé
                handleNext();
            }
        }, 5000); // Passage automatique toutes les 5 secondes

        // Cleanup de l'intervalle au démontage du composant
        return () => clearInterval(intervalId);
    }, [showMoreInfo, currentIndex]);

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            height: window.innerWidth <= 768 ? '650px' : '500px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            marginTop: "3rem",
            paddingTop: window.innerWidth <= 768 ?'3%':'0'
        }}>
            <div style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden'
                
            }}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                {carouselData.map((item, index) => {
                    const isCurrent = index === currentIndex;
                    const isPrevious = index === currentIndex - 1 || (currentIndex === 0 && index === carouselData.length - 1);
                    const isNext = index === currentIndex + 1 || (currentIndex === carouselData.length - 1 && index === 0);

                    let initialTranslateX = '100%';
                    let initialOpacity = 0;
                    if (isPrevious) {
                        initialTranslateX = '-150%';
                        initialOpacity = 0;
                    } else if (isNext) {
                        initialTranslateX = '150%';
                        initialOpacity = 0;
                    }

                    if (!isCurrent && !isPrevious && !isNext) {
                        return null;
                    }

                    const isMobile = window.innerWidth <= 768;

                    return (
                        <div
                            key={item.id}
                            style={{
                                position: 'absolute',
                                width: isMobile ? '75%' : '70%',
                                backgroundColor: '#EEEDFE',
                                borderRadius: '40px',
                                padding: '30px',
                                opacity: isCurrent ? 1 : initialOpacity,
                                transform: `translateX(${isCurrent ? '0' : initialTranslateX}) scale(${isCurrent ? 1 : 0.9})`,
                                transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out, height 0.3s ease-in-out',
                                display: 'flex',
                                flexDirection: isMobile ? 'column' : 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                overflow: 'hidden',
                                top: '0',
                                height: isMobile
                                    ? showMoreInfo && isCurrent
                                        ? 'auto' // Allow height to expand when showMoreInfo is true
                                        : '82%'  // Default height when info is hidden
                                    : '80%',
                            }}
                        >
                            <div style={{
                                flex: isMobile ? 'none' : '0.7',
                                padding: isMobile ? '20px 5%' : '0 10%',
                                order: isMobile ? 2 : 1,
                                width: isMobile ? '100%' : 'auto'
                            }}>
                                <h3 style={{ fontSize: 'clamp(24px, 1.9vw, 34px)', color: '#2112B7', marginBottom: '15px' }}>
                                    {item.title}
                                </h3>
                                <p style={{ fontSize: 'clamp(18px, 1.5vw, 22px)', fontWeight: 'bold', color: '#333', marginBottom: '10px' }}>
                                    {item.catchphrase}
                                </p>
                                <p style={{ fontSize: 'clamp(16px, 1.2vw, 20px)', color: '#666', marginBottom: '20px' }}>
                                    {item.technical_detail}
                                </p>
                                {showMoreInfo && isCurrent && (
                                    <p style={{ fontSize: '14px', color: '#444', marginTop: '10px' }}>{item.more_info}</p>
                                )}
                                <button
                                    onClick={() => setShowMoreInfo(!showMoreInfo)}
                                    style={{
                                        padding: '10px 25px',
                                        backgroundColor: '#2112B7',
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '35px',
                                        cursor: 'pointer',
                                        fontSize: 'clamp(14px, 1vw, 18px)'
                                    }}
                                >
                                    {showMoreInfo ? 'Réduire' : 'En savoir plus'}
                                </button>
                            </div>
                            <div style={{
                                flex: isMobile ? 'none' : 1,
                                display: 'flex',
                                justifyContent: 'center',
                                order: isMobile ? 1 : 2,
                                height: isMobile ? '200px' : 'auto',
                                width: isMobile ? '100%' : 'auto',
                                marginBottom: isMobile ? '20px' : 0
                            }}>
                                <img
                                    src={item.url_photo}
                                    alt={item.title}
                                    style={{
                                        maxWidth: '100%',
                                        height: isMobile ? '100%' : 'auto',
                                        objectFit: 'contain'
                                    }}
                                />
                            </div>
                            {isCurrent && !isMobile && (
                                <>
                                    <button
                                        onClick={handlePrevious}
                                        style={{
                                            position: 'absolute',
                                            left: isMobile ? '10px' : '30px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '50%',
                                            backgroundColor: 'white',
                                            border: 'none',
                                            cursor: 'pointer',
                                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                            zIndex: 10
                                        }}
                                    >
                                        ←
                                    </button>
                                    <button
                                        onClick={handleNext}
                                        style={{
                                            position: 'absolute',
                                            right: isMobile ? '10px' : '30px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '50%',
                                            backgroundColor: 'white',
                                            border: 'none',
                                            cursor: 'pointer',
                                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                            zIndex: 10
                                        }}
                                    >
                                        →
                                    </button>
                                </>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
