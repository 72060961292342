import { useEffect, useState } from "react";

export const DetailedVideo = () => {
    const [isMobile, setIsMobile] = useState<boolean>(false)

    useEffect(() => {
        if(window.innerWidth < 768){
            setIsMobile(true)
        }else{
            setIsMobile(false)
        }
    }, [isMobile])
    

    return (
        <div style={{display: 'flex', flexDirection: isMobile?'column':'row', alignItems: 'center', width: '90%', marginLeft:"5%"}}>
            <div style={{width: isMobile ? '95%' : '45%', padding: '2vw'}}>
                <div style={{fontSize:"clamp(22px, 1.6vw, 34px)", fontWeight: "bold", textAlign:'center'}}> Vidéo dynamique </div>
                <div style={{fontSize:"clamp(18px, 1.4vw, 28px)", fontWeight: "normal", color:'#1B1464', textAlign:'center'}}> Plongez au cœur de l’action. </div>
                <div style={{fontSize:"clamp(14px, 1vw, 20px)", fontWeight: "normal", marginTop:"1vw", textAlign:'justify'}}> Avec des prises de vue fluides et immersives, chaque vidéo devient un récit captivant. Que ce soit en plein vol ou au sol, nos drones et caméras haut de gamme captent chaque mouvement avec une précision cinématographique. Laissez-nous transformer vos idées en vidéos percutantes et mémorables, qui font ressentir chaque instant avec intensité.</div>
            </div>
            <div style={{width: isMobile?'100%':'55%'}}>
                <iframe width="100%" style={{borderRadius:"15px", aspectRatio: "16/9"}} src="https://www.youtube.com/embed/EQ37X7eS_ys" title="Drone FPV &amp; Drift - Jap&#39;n&#39;Car 2024" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>
            
        </div>
    );
};

