import React, { useEffect, useRef, useState } from 'react';
import Button from './ui/Button';

function LandingVideo() {
    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const [isOverlayVisible, setisOverlayVisible] = useState<boolean>(true);
    const [videoHeight, setVideoHeight] = useState<number>(0);

    useEffect(() => {
        // Fonction pour calculer la hauteur de la vidéo en fonction de la largeur
        const calculateVideoHeight = () => {
            const width = window.innerWidth;
            let height = (width * 9) / 16; // Ratio 16:9
            const maxHeight = window.innerHeight; // S'assurer que ça ne dépasse pas la hauteur de l'écran
        
            if (height > maxHeight) {
                height = maxHeight; // Ajustement pour éviter une zone vide
            }
        
            setVideoHeight(height);
        };
        

        // Appeler la fonction au chargement
        calculateVideoHeight();

        // Ajouter un écouteur pour la redimensionnement de la fenêtre
        window.addEventListener('resize', calculateVideoHeight);

        // Nettoyage de l'écouteur lors du démontage
        return () => {
            window.removeEventListener('resize', calculateVideoHeight);
        };
    }, []);

    return (
        <div>
            <section
                style={{
                    position: "relative",
                    width: "100%",
                    height: videoHeight, // Appliquer la hauteur calculée dynamiquement
                    overflow: "hidden",
                    margin: 0,
                    padding: 0,
                }}
            >
                <iframe
                    ref={iframeRef}
                    src="https://player.vimeo.com/video/1051484187?background=1&loop=1&autoplay=1&muted=1"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        width: "120%",
                        height: "120%",
                        transform: "translate(-50%, -50%)",
                        margin: 0,
                        padding: 0,
                        border: "none",
                    }}
                    title="Vimeo Background Video"
                ></iframe>

                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: isOverlayVisible ? "flex" : "none",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                    }}
                >
                    <div style={{
                        color: 'white',
                        fontSize: 'clamp(1.2rem, 4vw, 3rem)',
                        fontWeight: 'bold',
                        marginBottom: '10px',
                        maxWidth: '90%',
                    }}>
                        Laissez nos drones raconter vos histoires
                    </div>
                    <div style={{
                        color: 'white',
                        fontSize: 'clamp(1rem, 3vw, 1.5rem)',
                        maxWidth: '70%',
                        marginBottom: '20px',
                    }}>
                        Donnez vie à vos idées et impressionnez votre audience avec des images uniques et inspirantes
                    </div>
                    <Button onClick={() => setisOverlayVisible(false)} text="Commencer l'aventure" />
                </div>
            </section>

            {/* Lien discret sous la vidéo */}
            <div style={{
                position: 'relative',
                marginTop: '10px', // Espace sous la vidéo
                textAlign: 'center',
            }}>
                <a
                    href="https://player.vimeo.com/video/1051484187?autoplay=1&muted=0"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        color: 'black',
                        fontSize: '1rem',
                        textDecoration: 'underline',
                    }}
                >
                    Découvrir en 4K avec son immersif
                </a>
            </div>
        </div>
    );
}

export default LandingVideo;
