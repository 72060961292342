import React from 'react'
import PicHeader from './ui/PicHeader'
import { ServiceCards } from './ServiceCards'


const Services = () => {
    return (
        <div>
            <PicHeader url="/ressources/bromo.jpg" title="Services" caption="Mount Bromo, Indonesia"/>
            <ServiceCards/>
        </div>
    )
}

export default Services