export interface ProjectType {
    id: number;
    title: string;
    description: string;
    imageUrl: string;
    link?: string;
    date: string;
    tags: string[];
    client: string;
}

interface BonusProjectType {
    id: number;
    title: string;
    client: string;
    date: string;
}

export const projects: ProjectType[] = [
    {
        id: 1,
        title: "Film - I love Peru (avec Raphaël Quenard)",
        description: "Séquences aérienne pour un film avec une sortie en salle en mai 2025",
        imageUrl: "/ressources/peru.png",
        date: "2024",
        tags: [
            "Cinéma"
        ],
        client: "Lipsum Production"
    },
    {
        id: 2,
        title: "Inauguration - Exposition photo",
        description: "Captation de l'inauguration de l'exposition \"L'art du sport\" à Lieusaint",
        imageUrl: "/ressources/inaug_2.png",
        link: "https://www.instagram.com/p/DAiLgw1vd-_/",
        date: "2024",
        tags: [
            "Événementiel",
            "Culture"
        ],
        client: "Club Photo Passion Partage"
    },
    {
        id: 3,
        title: "Jap'n'Car Festival",
        description: "Une journée de spectacle automobile sur le circuit UTAC de Linas Montlhéry - Drift, expositions et shows",
        imageUrl: "/ressources/vroum.png",
        link: "https://www.youtube.com/watch?v=EQ37X7eS_ys",
        date: "2024",
        tags: [
            "Événementiel",
            "Sport"
        ],
        client: "Midnight Society"
    }
];

export const bonusProjects: BonusProjectType[] = [
    {
        id: 1,
        title: 'Film mariage',
        client: 'Jean Marc & Sylvie',
        date: '2023'
    },
    {
        id: 2,
        title: 'Inspection toiture',
        client: 'Nathalie C',
        date: '2023'
    },
    {
        id: 3,
        title: 'Photographie aérienne',
        client: 'Eco-Vision',
        date: '2023'
    },
    {
        id: 4,
        title: 'Film Aki Party (convention)',
        client: 'Asian Efrei',
        date: '2023'
    },
]

