import { DetailedEditing } from "../Components/detailed-service/DetailedEditing";
import { DetailedPhoto } from "../Components/detailed-service/DetailedPhoto";
import { DetailedVideo } from "../Components/detailed-service/DetailedVideo";
import { ServiceCard } from "../Types/servicesCard.types"

export const CARDS_DATA: ServiceCard[] = [
  {
    id: "1",
    icon: "@mui/icons-material/CameraAltOutlined",
    title: "Photographie aérienne",
    subtitle: "Immobilier, évènements, nature...",
    items: [
      "Photos aériennes haute résolution",
      "Inspection technique détaillée",
      "Couverture d'événements",
      "Mariages"
    ],
    content: DetailedPhoto,
    xsmall: "12%",
    ysmall: "75%",
    x: "27%",
    y: "37%",
    description: "Capturez des images aériennes en haute définition avec des angles uniques."
  },
  {
    id: "2",
    icon: "@mui/icons-material/MovieOutlined",
    title: "Production vidéo",
    subtitle: "Évènementiel, publicités, cinéma...",
    items: [
      "Plans aériens FPV et stabilisés en haute définition",
      "Prises de vues au sol complémentaires",
      "Suivi de sujet en mouvement",
      "Survol de bâtiments et paysages"
    ],
    content: DetailedVideo,
    xsmall: "46%",
    ysmall: "75%",
    x: "48%",
    y: "31%",
    description: "Filmez des vidéos fluides et dynamiques depuis les airs."
  },
  {
    id: "3",
    icon: "@mui/icons-material/AutoAwesomeOutlined",
    title: "Montage vidéo",
    subtitle: "Étalonnage, montage créatif, effets...",
    items: [
      "Montage dynamique et professionnel",
      "Étalonnage colorimétrique sur-mesure",
      "Sound design et musiques",
      "Effets visuels et animations"
    ],
    content: DetailedEditing,
    xsmall: "80%",
    ysmall: "75%",
    x: "70%",
    y: "40%",
    description: "Transformez vos prises de vue en œuvres cinématographiques."
  }
];